import React, { Component } from "react";
import {
  DataPeriod,
  EvidenceColumn,
  EvidencePeriodData,
  EvidenceType,
  MeasurementType,
  NeedsAreaTag,
  PossibleValue,
  StudentEntry,
} from "../../../../../../../store/onboarding/types";
import {
  drawCell,
  pickColor,
  pickTextColorBasedOnBgColor,
} from "../../../../../../utils";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import BehaviorDataCell from "./BehaviorDataCell";
import { ApplicationState } from "../../../../../../../store";
import { connect } from "react-redux";
import { fontWeight } from "html2canvas/dist/types/css/property-descriptors/font-weight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

type OwnProps = {
  isReadonly?: boolean;
  studentEntry: StudentEntry;
  evidenceColumn: EvidenceColumn;
  dataPeriod?: DataPeriod;
};

type PropsFromState = {
  evidencePeriodData?: EvidencePeriodData | undefined;
};

const DIAGNOSTIC_DIAGNOSTIC_TAG_NUM = 3;
const NEEDS_AREA_TAG_NUM = 3;

type Props = OwnProps & PropsFromState;

const getDisplayName = (
  studentEntry: StudentEntry,
  evidenceColumn: EvidenceColumn
): string => {
  const evidenceType = evidenceColumn.evidence_type;

  const value = studentEntry.value;
  const targetBehavior = studentEntry.target_behavior;

  if (evidenceType === EvidenceType.Data && value !== undefined) {
    const measurement = evidenceColumn.measurement;
    if (measurement.type === MeasurementType.CATEGORY) {
      const possibleValue:
        | PossibleValue
        | undefined = measurement.possible_values!.find(
        (possibleValue) => possibleValue.order === +value
      );
      if (possibleValue) {
        return possibleValue.display_name;
      }
    } else {
      return value;
    }
  } else if (
    evidenceType === EvidenceType.TargetBehavior &&
    targetBehavior !== undefined
  ) {
    if (targetBehavior.replacement) {
      return targetBehavior.replacement.name;
    } else {
      return targetBehavior.behavior.name;
    }
  }

  return "";
};

class DataCell extends Component<Props> {
  render() {
    const { studentEntry, evidenceColumn, dataPeriod, evidencePeriodData } = this.props;
    const bgColor = pickColor(
      studentEntry.value,
      evidenceColumn.measurement,
      studentEntry.grade,
      dataPeriod,
      evidencePeriodData
    );
    const textColor = pickTextColorBasedOnBgColor(bgColor);
    const displayValue = getDisplayName(studentEntry, evidenceColumn);

    let cellContent;
    switch (evidenceColumn.evidence_type) {
      case EvidenceType.Data: {
        const value = drawCell(displayValue, evidenceColumn.measurement);
        const isCategory =
          evidenceColumn.measurement.type === MeasurementType.CATEGORY;

        cellContent = (
          <p
            title={value}
            style={{
              color: textColor,
            }}
            className={isCategory ? "category" : undefined}
          >
            {value}
          </p>
        );
        break;
      }
      case EvidenceType.TargetBehavior:
        return <div />;
      case EvidenceType.ExistingBehavior:
        cellContent = (
          <BehaviorDataCell
            isReadonly={this.props.isReadonly}
            displayValue={displayValue}
            studentEntry={studentEntry}
          />
        );
        break;
      case EvidenceType.NeedAreas: {
        if (
          !studentEntry.needs_area_tags ||
          studentEntry.needs_area_tags.length == 0
        ) {
          cellContent = <div />;
          break;
        }

        const tags = studentEntry.needs_area_tags || [];

        
        let marginTop;
        let fontSize: number | undefined;

        if (tags.length === 2) {
          marginTop = 2;
        } else if (tags.length === 1) {
          marginTop = 2;
        } else if (tags.length === 3) {
          marginTop = 1;
          fontSize = 10;
        }
        let areaPs;
        let sortingArr = [
          'letter naming', 
          'early phonological awareness',
          'phonological awareness', 
          'phonics', 
          'decoding', 
          'sight words',
          'fluency',
          'phonics and/or fluency',
          'vocabulary and/or reading comprehension'
        ]
        if (tags.length === 3 || tags.length === 4) {
          const names = tags.map((tag) => tag.display_name);
          if(names.length) {
            if (['G4','G5','G6','G7','G8'].includes(studentEntry.grade!) &&
                names.includes('Phonics') && names.includes('Fluency')){
                const filteredArray = names.filter((name)=> !['Fluency', 'Phonics'].includes(name));
                areaPs = <>
                            <p  className="mb-0">Phonics and/or Fluency</p>
                            <p  className="mb-0">{[...filteredArray].sort((a, b) => sortingArr.indexOf(a.toLowerCase()) - sortingArr.indexOf(b.toLowerCase())).join(', ')}</p>
                          </>;
            } else {
            //for dibils data and custom assessment
              if((evidencePeriodData?.evidence_column_groups != null) 
                && (evidencePeriodData?.evidence_column_groups[0].assessment != null) 
                && ((evidencePeriodData?.evidence_column_groups[0].assessment.special_case == null)
                  || evidencePeriodData?.evidence_column_groups[0].assessment.special_case?.includes('dibels'))) {
                  let nameArray1:any[] = [];
                  let nameArray2:any[] = [];
                  let nameArray3:any[] = [];
                  let nameArray4:any[] = [];
                  names.forEach((name, index) => {
                    if(['Phonics','Decoding','Sight Words'].includes(name)) {
                      nameArray1.push(name);
                    } 
                    if(['Fluency'].includes(name)) {
                      nameArray2.push(name);
                    }  
                    if(['Phonological Awareness'].includes(name)) {
                      nameArray3.push(name);
                    }
                    if(['Letter Names'].includes(name)) {
                      nameArray4.push(name);
                    }
                  })
                  areaPs = <>
                              {nameArray1.length ? <p  className="mb-0">{nameArray1.sort((a, b) => sortingArr.indexOf(a.toLowerCase()) - sortingArr.indexOf(b.toLowerCase())).join(', ')}</p> : ''} 
                              {nameArray2.length ? <p  className="mb-0">{nameArray2.sort((a, b) => sortingArr.indexOf(a.toLowerCase()) - sortingArr.indexOf(b.toLowerCase())).join(', ')}</p> : ''}  
                              {nameArray3.length ? <p  className="mb-0">{nameArray3.sort((a, b) => sortingArr.indexOf(a.toLowerCase()) - sortingArr.indexOf(b.toLowerCase())).join(', ')}</p> : ''} 
                              {nameArray4.length ? <p  className="mb-0">{nameArray4.sort((a, b) => sortingArr.indexOf(a.toLowerCase()) - sortingArr.indexOf(b.toLowerCase())).join(', ')}</p> : ''} 
                            </>
              } else {
                // for behavior data
                areaPs = <p  className="mb-0">{names.sort((a, b) => sortingArr.indexOf(a.toLowerCase()) - sortingArr.indexOf(b.toLowerCase())).join(', ')}</p>;
              }
            }
          }
        } else {
          areaPs = tags.sort((a: NeedsAreaTag, b: NeedsAreaTag) => sortingArr.indexOf(a.display_name.toLowerCase()) - sortingArr.indexOf(b.display_name.toLowerCase())).slice(0, 2).map((tag) => {
            return <p key={tag.id} className="mb-0">{tag.display_name}</p>;
          });
          // Special condition because we don't have Phonics and/or Fluency area of needs as of now.
          const tagArray = tags.map((tag)=> tag.display_name);
          if (['G4','G5','G6','G7','G8'].includes(studentEntry.grade!) &&
              tagArray.includes('Phonics') && tagArray.includes('Fluency')){
              areaPs = <p key={tags[0].id}>Phonics and/or Fluency</p>
          }
        }
        cellContent = (
          <OverlayTrigger placement="left-end"
            overlay={<Tooltip id="editIcon" 
            style={{display: studentEntry.missing_area == null ? 'none' : '', cursor:studentEntry.missing_area == null ? 'none' : ''}}>This student does not have data for <span className="font-weight-bold" >{studentEntry.missing_area}</span> but may have a need in this/these area(s). To confirm, measuring this/these skill area(s) is recommended.</Tooltip>}
          >
          <div className="needsAreaDataCell">
            <div
              className="needsAreaItem"
              >
              <div className="d-flex">
                {studentEntry.missing_area != null ?
                  <div className="mb-0">
                    <FontAwesomeIcon
                    icon={faExclamationCircle}
                    size="sm"
                    style={{ color: "#00265b"}}
                    className="mr-1"
                    />
                  </div>
                  : ''}
                <div
                  style={{
                    marginTop: marginTop,
                    fontSize: fontSize,
                  }}
                >{areaPs}</div>

              </div>
            {studentEntry.needs_area_tags &&
              studentEntry.needs_area_tags.length > (NEEDS_AREA_TAG_NUM + 1) && (
                <>
                  <OverlayTrigger
                    overlay={
                      <Popover id={"teachersPopover"}>
                        <Popover.Content>
                          {studentEntry.needs_area_tags
                            .slice(NEEDS_AREA_TAG_NUM - 1)
                            .map((tag) => (
                              <div
                                className="needsAreaItemPopOver"
                                key={tag.id}
                                onClick={() => null}
                              >
                                {tag.display_name}
                              </div>
                            ))}
                        </Popover.Content>
                      </Popover>
                    }
                  >
                    <strong style={{ display: "inline-block", fontSize:'0.8rem' }}>
                      {`and ${studentEntry.needs_area_tags.length -
                        NEEDS_AREA_TAG_NUM + 1}+`}
                    </strong>
                  </OverlayTrigger>
                </>
              )}
            </div>
          </div>
          </OverlayTrigger>
        );
        break;
      }

      case EvidenceType.DiagnosticInfo:
        cellContent = (
          <div className="diagnosticInfoDataCell">
            {(studentEntry.diagnostic_tags || [])
              .slice(0, DIAGNOSTIC_DIAGNOSTIC_TAG_NUM)
              .map((tag) => (
                <div className="diagnosticInfoItem" key={tag.id}>
                  {tag.display_name.length < 12
                    ? tag.display_name
                    : tag.display_name.slice(0, 12) + "..."}
                </div>
              ))}
            {studentEntry.diagnostic_tags &&
              studentEntry.diagnostic_tags.length >
                DIAGNOSTIC_DIAGNOSTIC_TAG_NUM && (
                <>
                  <OverlayTrigger
                    overlay={
                      <Popover id={"teachersPopover"}>
                        <Popover.Content>
                          {studentEntry.diagnostic_tags
                            .slice(DIAGNOSTIC_DIAGNOSTIC_TAG_NUM)
                            .map((tag) => (
                              <div
                                className="diagnosticInfoItem"
                                key={tag.id}
                                onClick={() => null}
                              >
                                {tag.display_name}
                              </div>
                            ))}
                        </Popover.Content>
                      </Popover>
                    }
                  >
                    <strong style={{ display: "inline-block" }}>
                      {`and ${studentEntry.diagnostic_tags.length -
                        DIAGNOSTIC_DIAGNOSTIC_TAG_NUM} more`}
                    </strong>
                  </OverlayTrigger>
                </>
              )}
          </div>
        );
        break;
    }
    return (
      <div
        className="dataCell"
        style={{
          backgroundColor: bgColor,
        }}
      >
        {cellContent}
      </div>
    );
  }
}

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  return {
    evidencePeriodData: onboarding.evidencePeriodData
  };
};


export default connect(
  mapStateToProps,
  null
)(DataCell);

