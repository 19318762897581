import React, { FunctionComponent, useMemo } from "react";

type OwnProps = {
  topOffset?: number;
  students: number[];
  statsByEc: {
    belowColor?: string;
    atOrAboveColor?: string;
    below: number[];
    atOrAbove: number[];
  } | null;
};

type Props = OwnProps;

const BellowCell: FunctionComponent<Props> = (props) => {
  const { statsByEc, students, topOffset } = props;

  if (!statsByEc) {
    return (
      <td rowSpan={2} className="text-center p-2" style={{ top: topOffset }}>
        N/A
      </td>
    );
  }

  const belowCount = useMemo(
    () => statsByEc.below.filter((sId) => students.includes(sId)).length || 0,
    [statsByEc.below]
  );

  const atOrAboveCount = useMemo(
    () =>
      statsByEc.atOrAbove.filter((sId) => students.includes(sId)).length || 0,
    [statsByEc.atOrAbove]
  );

  return (
    <td
      className="text-center p-4 text-nowrap"
      style={{ backgroundColor: statsByEc.belowColor, top: topOffset }}
    >
      <strong>{`${((belowCount / (belowCount + atOrAboveCount || 1)) * 100)
        .toFixed(0)
        .concat("%")}`}</strong>
      &nbsp;({belowCount})
    </td>
  );
};

export default BellowCell;
