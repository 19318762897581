import React, { Component } from "react";
import { Cell } from "fixed-data-table-2";
import {
  DataPeriod,
  EvidenceColumn,
  EvidenceColumnGroup,
  EvidenceType,
  MeasurementType,
  PossibleValue,
  Student,
  StudentEntry,
} from "../../../../../../../store/onboarding/types";
import Select from "react-select";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faPlusCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import DataCell from "./DataCell";
import { StudentRowEditing } from "../../EvidenceTable";
import { Styles } from "react-select/src/styles";
import DiagnosticSkillDataEntryCell from "./DiagnosticSkillDataEntryCell";
import TargetBehaviorEmptyCell from "./TargetBehaviorEmptyCell";
import { bindActionCreators, Dispatch } from "redux";
import { openSkillFocusesModal } from "../../../../../../../store/groups/actions";
import { connect } from "react-redux";
import InterviewEntryCell from "./InterviewEntryCell";
import BehaviorDataCell from "./BehaviorDataCell";
import BehaviorScreenerDataEntryCell from "./BehaviorScreenerDataEntryCell";

type OwnProps = {
  isReadonly?: boolean;
  studentEditingRows: Array<StudentRowEditing>;
  evidenceColumn: EvidenceColumn;
  dataPeriod?: DataPeriod;
  evidenceColumnGroupId: number;
  student: Student;
  evidenceColumnGroup?: EvidenceColumnGroup;
  onSelectedValueChange: (
    evidenceColumnGroupId: number,
    evidenceColumnId: number,
    studentId: number
  ) => any;
  onStudentRowEditingChange: (
    evidenceColumnGroupId: number,
    evidenceColumnId: number,
    studentId: number
  ) => any;
  onKeyDown: (
    evidenceColumnGroupId: number,
    evidenceColumnId: number,
    studentId: number
  ) => any;
  onChangesApply: (
    evidenceColumnGroupId: number,
    evidenceColumnId: number,
    studentId: number,
    value?: number
  ) => any;
  onCellEditFormChange: (
    evidenceColumnGroupId: number,
    evidenceColumnId: number,
    studentId: number,
    value?: string
  ) => any;
};

type DispatchProps = {
  openSkillFocusesModal: (studentEntry?: StudentEntry) => any;
};

type Props = OwnProps & DispatchProps;

const CustomSelectStyles: Partial<Styles<PossibleValue, boolean>> = {
  control: (provided) => ({
    ...provided,
    minHeight: "25px",
    // lineHeight: "25px",
    fontSize: "14pt",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: "25px",
  }),
  placeholder: (provided) => ({
    ...provided,
    height: "25px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: "25px",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: "5px",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "5px",
  }),
};

class DataStudentEntryCell extends Component<Props> {
  handleFocus = (event: any) => {
    event.target.select();
  };

  getEmptyCell = (
    evidenceColumnGroupId: number,
    evidenceColumn: EvidenceColumn,
    student: Student,
    studentEntry?: StudentEntry
  ) => {
    const { onCellEditFormChange } = this.props;

    if (this.props.isReadonly) {
      return <Cell />;
    }

    switch (evidenceColumn.evidence_type) {
      case EvidenceType.Data:
        return (
          this.props.evidenceColumnGroup?.assessment.name !== 'SRSS' 
          ? 
            <Cell
              onClick={onCellEditFormChange(
                evidenceColumnGroupId,
                evidenceColumn.id,
                student.id!
              )}
              className="dataTableCellEmpty"
            >
              <FontAwesomeIcon icon={faPlusCircle} />
            </Cell>
          : <Cell/>
        );
      case EvidenceType.DiagnosticInfo:
        return (
          <DiagnosticSkillDataEntryCell
            studentEntry={studentEntry}
            studentId={student.id!}
            dataPeriod={this.props.dataPeriod}
          />
        );
      // case EvidenceType.Interview:
      case EvidenceType.NeedAreas:
        return (
          <Cell className="dataTableCellEmpty">
            <FontAwesomeIcon icon={faPlusCircle} />
          </Cell>
        );
      case EvidenceType.BehaviorScreener:
        return (
          <BehaviorScreenerDataEntryCell
            //studentEntry={studentEntry}
            student={student}
            evidenceColumnGroupId = {evidenceColumnGroupId}
            evidenceColumn = {evidenceColumn}
          />
        );
      case EvidenceType.TargetBehavior:
        return <div />;
      case EvidenceType.ExistingBehavior:
        return <TargetBehaviorEmptyCell student={student} />;
    }
  };

  render() {
    const {
      evidenceColumn,
      dataPeriod,
      student,
      evidenceColumnGroupId,
      studentEditingRows,
      onSelectedValueChange,
      onStudentRowEditingChange,
      onKeyDown,
      onCellEditFormChange,
      onChangesApply,
    } = this.props;
    const entry: StudentEntry | undefined = evidenceColumn.student_entries.find(
      (entry) => entry.student_id === student.id
    );
    const studentRowEditing = studentEditingRows
      .find((x) => x.studentId === student.id)
      ?.evidenceColumnGroups.find(
        (x) => x.evidenceColumnGroupId === evidenceColumnGroupId
      )
      ?.evidenceColumnEditingStatuses.find(
        (x) => x.evidenceColumnId === evidenceColumn.id
      );
    const isDataEvidence = evidenceColumn.evidence_type === EvidenceType.Data;
    const isCategoryType =
      isDataEvidence &&
      evidenceColumn.measurement.type === MeasurementType.CATEGORY;

    if (evidenceColumn.evidence_type === EvidenceType.ExistingBehavior) {
      return (
        <BehaviorDataCell
          studentEntry={entry}
          student={student}
          isReadonly={this.props.isReadonly}
        />
      );
    }

    return studentRowEditing?.isEdited && !this.props.isReadonly ? (
      <div className="editableDataCell">
        <div
          style={
            !isCategoryType
              ? { width: "65%", marginLeft: "auto", marginRight: "auto" }
              : { width: "100%" }
          }
        >
          {isCategoryType ? (
            <Select
              className="editableDataCellCategory"
              isSearchable={false}
              classNamePrefix="data_cell"
              placeholder="Select..."
              name="possibleValuesSelect"
              value={evidenceColumn.measurement.possible_values!.find(
                (possibleValue) =>
                  possibleValue.order === +studentRowEditing!.value!
              )}
              menuPortalTarget={document.body}
              options={evidenceColumn.measurement.possible_values!}
              getOptionLabel={({ display_name }: PossibleValue) => display_name}
              getOptionValue={({ order }: PossibleValue) => order.toString()}
              onChange={onSelectedValueChange(
                evidenceColumnGroupId,
                evidenceColumn.id,
                student.id!
              )}
              styles={CustomSelectStyles}
            />
          ) : (
            <input
              className="editableDataCellInput"
              onFocus={this.handleFocus}
              // autoFocus //todo read-311
              name="value"
              type="number"
              onChange={onStudentRowEditingChange(
                evidenceColumnGroupId,
                evidenceColumn.id,
                student.id!
              )}
              value={
                studentRowEditing.value !== undefined
                  ? +studentRowEditing.value
                  : ""
              }
              onKeyDown={onKeyDown(
                evidenceColumnGroupId,
                evidenceColumn.id,
                student.id!
              )}
            />
          )}
          <div className="editableDataCellOptions">
            {isCategoryType ? (
              <Button
                onClick={onCellEditFormChange(
                  evidenceColumnGroupId,
                  evidenceColumn.id,
                  student.id!
                )}
                style={{ marginTop: -5 }}
              >
                <FontAwesomeIcon icon={faTimesCircle} className="pointer" />
              </Button>
            ) : (
              <>
                <Button
                  onClick={onCellEditFormChange(
                    evidenceColumnGroupId,
                    evidenceColumn.id,
                    student.id!
                  )}
                >
                  <FontAwesomeIcon icon={faTimesCircle} className="pointer" />
                </Button>
                <Button
                  onClick={onChangesApply(
                    evidenceColumnGroupId,
                    evidenceColumn.id,
                    student.id!,
                    studentRowEditing.value
                  )}
                >
                  <FontAwesomeIcon icon={faCheckCircle} className="pointer" />
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    ) : entry && (evidenceColumn.evidence_type != EvidenceType.BehaviorScreener) ? (
      <Cell
        onDoubleClick={
          isDataEvidence
            ? onCellEditFormChange(
                evidenceColumnGroupId,
                evidenceColumn.id,
                student.id!,
                entry.value
              )
            : () => null
        }
        onClick={
          !this.props.isReadonly &&
          evidenceColumn.evidence_type === EvidenceType.DiagnosticInfo
            ? () => this.props.openSkillFocusesModal(entry)
            : () => null
        }
        className="dataTableCell"
      >
        <DataCell
          key={`${entry.entry_id}-${entry.student_id}-${entry.grade}`}
          studentEntry={entry}
          evidenceColumn={evidenceColumn}
          dataPeriod={dataPeriod}
        />
      </Cell>
    ) : evidenceColumn.evidence_type === EvidenceType.Interview ? (
      <InterviewEntryCell studentId={student.id!} />
    ) : (
      this.getEmptyCell(evidenceColumnGroupId, evidenceColumn, student, entry)
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      openSkillFocusesModal: openSkillFocusesModal,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(DataStudentEntryCell);
