import React, { useEffect, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretRight,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Collapse } from "react-bootstrap";
import { InterventionGroupMap } from "../../../../../../utils/Mappers";
import UserAvatarSmall from "../../../../../common/UserAvatarSmall";
import { push } from "connected-react-router";
import { getFullName } from "../../../../../../utils/NamesUtils";
import { ApplicationState } from "../../../../../../store";
import SvgCircle from "../common/SvgCircle";
import {
  AlertReason,
  GroupRecommendation,
  InterventionGroup,
} from "../../../../../../store/onboarding/cases/types";
import useUserRole from "../../../../../../utils/hooks/useUserRole";
import { useIntl } from "react-intl";
import {
  changeIntGroupAssigmentCollapsedSettings,
  openInterventionStatsModal
} from "../../../../../../store/onboarding/actions";
import { Student } from "../../../../../../store/onboarding/types";
import InterventionGroupGridView from "./InterventionGroupGridView";
import GroupCard from "../../../../../common/reading-interventions/intervention-tools/recommend-groups/group-card/GroupCard";
import { DataFilterState } from "../../../../../../store/dataFilter/types";
import { openOverviewModel } from "../../../../../../store/groups/actions";
import useUserAssignedToGroup from "./hooks/useUserAssignedToGroup";

type StateProps = {
  showArchivedGroups?: boolean;
  selectedInterventionGroupId?: number;
  collapsedIntGroupAssignmentsIds: number[];
};

type DispatchProps = {
  push: (location: string) => any;
  changeIntGroupAssigmentCollapsedSettings: (args: {
    id: number;
    collapsed?: boolean;
  }) => any;
};

type OwnProps = {
  isReadonly?: boolean;
  interventionGroupMap: InterventionGroupMap;
  student?: Student;

  normalizedNotFinishedGroups: GroupRecommendation[];
  notFinishedGroups: InterventionGroup[];
  onFinishGroup: any;
  onUnsaveGroup: any;
  onSaveName: any;
  onChangeGoalType: any;
  onChangeTier: any;
  onChangeTeacher: any;
  onChangeCoach: any;
  onAddStudent: any;
  onRemoveStudent: any;
  onRemoveFocus: any;
  onRemoveIntervention: any;
  onDragDropStudent: any;
  onClickSelectTime: any;
  onInterventionLibraryModal: any;
  onAddInterventionModal: any;
  onDragInterventionEnd: any;
  onDragInterventionUpdate: any;

  onDragStart: any;
  onDragEnd: any;
  teachers: any;
  coaches: any;
  isLoadingPatchInterventionGroup: any;
  isTeacher: boolean;
  isCoach: boolean;
  isDistrictOwner: boolean;
  //shareDataWith?: boolean;
  notShareDataWith?: boolean;
  isYourAssignments?: boolean;
  intGrpIDsWithReadAccess?: any;
  intGrpIDsWithEditAccess?: any;
};

type Props = OwnProps & StateProps & DispatchProps;

const InterventionsGroup: React.FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  const {
    normalizedNotFinishedGroups,
    notFinishedGroups,
    onFinishGroup,
    onUnsaveGroup,
    onSaveName,
    onChangeGoalType,
    onChangeTier,
    onChangeTeacher,
    onChangeCoach,
    onAddStudent,
    onRemoveStudent,
    onRemoveFocus,
    onRemoveIntervention,
    onDragDropStudent,
    onClickSelectTime,
    onInterventionLibraryModal,
    onAddInterventionModal,
    onDragInterventionEnd,
    onDragInterventionUpdate,
    isLoadingPatchInterventionGroup,
    student,
    teachers,
    coaches,
    onDragStart,
    onDragEnd,
    //shareDataWith,
    notShareDataWith,
    intGrpIDsWithReadAccess,
    intGrpIDsWithEditAccess
  } = props;

  const [collapsed, setCollapsed] = useState(false);

  const handleCollapse = () => {
    props.changeIntGroupAssigmentCollapsedSettings({
      id: employeeAssignment.id,
      collapsed: !collapsed,
    });
    setCollapsed((pS) => !pS);
  };

  const isStudentDetail = useSelector<
  ApplicationState
  >((s) => s.cases.isStudentDetail);

  const displayGroupChange = useSelector<
    ApplicationState
  >((s) => s.cases.displayGroupName);

  const tabName:any = useSelector<ApplicationState>((s)=> s.cases.tabName);
  
  const {
    employeeAssignment,
    interventionGroups,
    unfinishedGroupsIds,
  } = props.interventionGroupMap;
  const { showArchivedGroups, isReadonly, isTeacher, isCoach, isDistrictOwner } = props;

  const notificationCount = useMemo(() => {
    return interventionGroups.reduce((pV: number, cV) => {
      if (cV.alert_reason && cV.alert_reason != AlertReason.None) {
        return pV + 1;
      }

      return pV;
    }, 0);
  }, [interventionGroups]);

  const { isParent } = useUserRole();
  const intl = useIntl();

  useEffect(() => {
    setCollapsed((c) =>
      c
        ? c
        : props.collapsedIntGroupAssignmentsIds.includes(employeeAssignment.id)
    );
  }, [employeeAssignment]);

  useEffect(() => {
    if (
      props.selectedInterventionGroupId &&
      !collapsed &&
      interventionGroups.some(
        (ig) => ig.id === props.selectedInterventionGroupId
      )
    ) {
      setCollapsed(true);
    }
  }, [props.selectedInterventionGroupId]);

  const activeView = useSelector<ApplicationState, "list" | "grid">(
    (s) => s.cases.activeGroupsView
  );

  const unfinishedGroups = useMemo(() => {
    return unfinishedGroupsIds
      ? normalizedNotFinishedGroups.filter((g) =>
          unfinishedGroupsIds.includes(g.id!)
        )
      : [];
  }, [unfinishedGroupsIds, normalizedNotFinishedGroups]);

  const { groupSort, exitedStudentsGroup } = useSelector<
    ApplicationState,
    DataFilterState
  >((s) => s.dataFilter);

  let exists;

  return (
    <div>
      <div
        data-cy="employee_assignment"
        id={`employee_assignment_${employeeAssignment.id}`}
        className="assigmentCollapseHeader"
        aria-controls={"assigment-collapse"}
        aria-expanded={ collapsed}
        onClick={handleCollapse}
      >
        {(!notShareDataWith || displayGroupChange == 'archived') && !groupSort && (
          <span className="mr-3">
            <FontAwesomeIcon
              icon={collapsed ? faCaretDown : faCaretRight}
              size="2x"
            />
          </span>
        )}
        <span className="mr-3">
          <UserAvatarSmall userInfo={employeeAssignment.user} />
        </span>
        <h2>{getFullName(employeeAssignment.user)}</h2>

        {!isParent && !collapsed && !!notificationCount && (
          <SvgCircle
            svgSize={30}
            text={notificationCount}
            className="notificationBubble mx-2"
          />
        )}
        {!notShareDataWith ?
            <button
              className="ml-2 blueBtnSm"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(openInterventionStatsModal(employeeAssignment.user));
                dispatch(openOverviewModel('one_educator'));
              }}
            >
              Show Overview
            </button>
          :
          ''
        }

        {/* {(!notShareDataWith || displayGroupChange == 'archived') && collapsed && !isReadonly && !showArchivedGroups && !groupSort && (
          <button
            className="newCaseBtn ml-auto"
            onClick={() => props.push(`/data`)}
          >
            <FontAwesomeIcon icon={faPlusCircle} />
            {intl.formatMessage({
              id:
                "app.dashboard.yourAssigmentTab.interventionsGroupItem.addNewCaseTitle",
            })}
          </button>
        )} */}
      </div>
      <Collapse in={(!notShareDataWith || displayGroupChange == 'archived') &&  (collapsed || !!groupSort)}>
        <div id="assigment-collapse">
          <div className={`assigmentInfo ${activeView}`}>
            {
              <>
                {interventionGroups.map((interventionGroup, index) => {
                  if(isStudentDetail && (isCoach || isTeacher) && !intGrpIDsWithReadAccess?.length) {
                    return <InterventionGroupGridView
                              key={interventionGroup.id}
                              isReadonly={isReadonly}
                              showArchivedGroups={showArchivedGroups}
                              interventionGroup={interventionGroup}
                              student={props.student}
                              onDragDropStudent={onDragDropStudent(interventionGroup)}
                              exitedStudentsGroup={exitedStudentsGroup}
                              //shareDataWith={shareDataWith}
                              notShareDataWith={notShareDataWith}
                            /> 
                  } else if(isStudentDetail && isDistrictOwner) {
                    return <InterventionGroupGridView
                              key={interventionGroup.id}
                              isReadonly={isReadonly}
                              showArchivedGroups={showArchivedGroups}
                              interventionGroup={interventionGroup}
                              student={props.student}
                              onDragDropStudent={onDragDropStudent(interventionGroup)}
                              exitedStudentsGroup={exitedStudentsGroup}
                              //shareDataWith={shareDataWith}
                              notShareDataWith={notShareDataWith}
                            /> 
                  } else {
                      if(intGrpIDsWithEditAccess) { 
                        if (intGrpIDsWithEditAccess.includes(interventionGroup.id)) {
                          return <InterventionGroupGridView
                                  key={interventionGroup.id}
                                  isReadonly={isReadonly}
                                  showArchivedGroups={showArchivedGroups}
                                  interventionGroup={interventionGroup}
                                  student={props.student}
                                  onDragDropStudent={onDragDropStudent(interventionGroup)}
                                  exitedStudentsGroup={exitedStudentsGroup}
                                  //shareDataWith={shareDataWith}
                                  notShareDataWith={notShareDataWith}
                                /> 
                        } 
                      }
  
                      if(intGrpIDsWithReadAccess) {
                        if(intGrpIDsWithReadAccess.includes(interventionGroup.id)) {
                          return  <InterventionGroupGridView
                            key={interventionGroup.id}
                            isReadonly={isReadonly}
                            showArchivedGroups={showArchivedGroups}
                            interventionGroup={interventionGroup}
                            student={props.student}
                            onDragDropStudent={onDragDropStudent(interventionGroup)}
                            exitedStudentsGroup={exitedStudentsGroup}
                            //shareDataWith={shareDataWith}
                            notShareDataWith={notShareDataWith}
                          /> 
                        }
                      }
                        
                    //}
                  }
                
              })}
                {!groupSort && intGrpIDsWithEditAccess &&
                  unfinishedGroups?.filter(
                      (unfinish) =>
                        tabName == 'groups/behavior'
                        ? unfinish.goal_type == 'behavior' 
                        : unfinish.goal_type == 'academic'
                      ).map((group) => (
                    <>
                      {
                        (exists = group.students.some(
                          (v) => v.id === student?.id
                        ))
                      }
                      {(student == undefined || exists) && (
                        <GroupCard
                          key={group.id}
                          interventionGroup={notFinishedGroups.find(
                            (ig) => group.id != null && ig.id === group.id //todo optimize
                          )}
                          groupRecommendation={group}
                          onInterventionLibraryModal={onInterventionLibraryModal(
                            group
                          )}
                          onAddInterventionModal={onAddInterventionModal(group)}
                          teachers={teachers}
                          student={student}
                          coaches={coaches}
                          isTeacher={isTeacher}
                          isPatchLoading={isLoadingPatchInterventionGroup}
                          onSaveGroup={() => onFinishGroup(group)}
                          onRemoveGroup={() => onUnsaveGroup(group)}
                          onSaveName={onSaveName(group)}
                          onChangeGoalType={onChangeGoalType(group)}
                          onChangeTier={onChangeTier(group)}
                          onChangeTeacher={onChangeTeacher(group)}
                          onChangeCoach={onChangeCoach(group)}
                          onAddStudent={onAddStudent(group)}
                          onRemoveStudent={onRemoveStudent(group)}
                          onRemoveFocus={onRemoveFocus(group)}
                          onRemoveIntervention={onRemoveIntervention(group)}
                          onClickSelectTime={onClickSelectTime(group)}
                          onDragDropStudent={onDragDropStudent(group)}
                          onDragStart={onDragStart}
                          onDragEnd={onDragEnd}
                          onDragInterventionEnd={onDragInterventionEnd(group)}
                          onDragInterventionUpdate={onDragInterventionUpdate(
                            group
                          )}
                        />
                      )}
                    </>
                  ))}
              </>
            }
          </div>
        </div>
      </Collapse>
    </div>
  );
};

const mapStateToProps = ({
  onboarding,
  dataFilter,
  tour,
  cases
}: ApplicationState): StateProps => {
  return {
    collapsedIntGroupAssignmentsIds: onboarding.collapsedIntGroupAssignmentsIds,
    showArchivedGroups: dataFilter.showArchivedGroups,
    selectedInterventionGroupId: tour.selectedInterventionGroupId,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      push: push,
      changeIntGroupAssigmentCollapsedSettings: changeIntGroupAssigmentCollapsedSettings
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InterventionsGroup);
