import React, { Component, useRef } from "react";
import { Spinner, Row, Col } from "react-bootstrap";
import { faArrowRight, faCircle, faDotCircle, faExclamationCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Assessment,
  DataPeriod,
  EvidencePeriodData,
  GradeLevels,
  ReactSelectOption,
} from "../../../../../store/onboarding/types";
import { connect, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";
import Select, { ValueType } from "react-select";
import {
  Grade,
  TimePeriod,
  TimePeriodOptions,
} from "../../../../../store/groups/types";
import { UserAssignmentRole } from "../../../../../store/auth/types";

type PropsFromState = {
  evidencePeriodData?: EvidencePeriodData;
  isLoading: {
    getEvidenceInDataPeriodLoading: boolean;
  };
  currentRole?: string;
  dataPeriods: DataPeriod[];
  myId?: number;
};
type OwnProps = {
  handleSaveStudentData: (
    assessment: Assessment,
    grade?: Grade,
    timePeriod?: TimePeriod,
    year?: string,
    upload_in_existing_shared_dp?: boolean,
    shared_data_period_selected?: number[],
    new_students_only?: string
  ) => any;
  handleAcceptFile: () => void;
  assessments: Array<Assessment>;
  uploadAssessmentData: boolean;
  rawData?: any;
};

type Props = OwnProps & PropsFromState;

type State = {
  selectedAssessment?: Assessment;
  grade?: Grade;
  timePeriod?: TimePeriod;
  year?: string;
  isConfirmAndUploadBtnDisable?: boolean;
  upload_in_existing_shared_dp?: boolean;
  shared_data_period_selected?: number[];
  isContinueToUpload?: boolean;
  isnewStudentsOnly?: boolean;
  new_students_only?: string;
  selectDataPeriodRef: React.RefObject<HTMLInputElement>;
  videoVisible: boolean;
};

enum ReplaceAppendNewOption {
  CREATE = 'create',
  REPLACE = 'replace',
  APPEND = 'append',
  NEW = 'new'
}

class FileRecognizedContent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedAssessment: this.props.assessments[0],
      isConfirmAndUploadBtnDisable: true,
      upload_in_existing_shared_dp: undefined,
      shared_data_period_selected: undefined,
      isContinueToUpload: undefined,
      isnewStudentsOnly: undefined,
      new_students_only: undefined,
      selectDataPeriodRef: React.createRef(),
      videoVisible: false
    };
  }

  handleAssessmentSelect = (assessment: Assessment) => () => {
    this.setState({ selectedAssessment: assessment });
  };

  handleSelectAssessmentConfirm = () => {
    const { selectedAssessment, 
            grade, 
            timePeriod, 
            year, 
            upload_in_existing_shared_dp, 
            shared_data_period_selected,
            new_students_only 
    } = this.state;
    if (selectedAssessment) {
      this.props.handleSaveStudentData(
        selectedAssessment,
        grade,
        timePeriod,
        year,
        upload_in_existing_shared_dp,
        shared_data_period_selected,
        (new_students_only == ReplaceAppendNewOption.CREATE) ? ReplaceAppendNewOption.REPLACE : new_students_only
      );
    }
  };

  handleContinueToUploadReset = () => {
    this.setState({
      ...this.state,
      upload_in_existing_shared_dp: undefined,
      isContinueToUpload : undefined,
      isnewStudentsOnly : undefined,
      new_students_only: undefined,
      shared_data_period_selected: undefined,
      isConfirmAndUploadBtnDisable: true,
      videoVisible: false
    })
  }

  handleSharedDataPeriodChange = (options: ValueType<DataPeriod, true>) => {
    if (options && (options as DataPeriod[]).length > 0) {
      this.setState({
        shared_data_period_selected: (options as DataPeriod[]).map((t) => t.id),
        isConfirmAndUploadBtnDisable: 
          this.state.upload_in_existing_shared_dp 
          && this.state.isContinueToUpload
          && this.state.new_students_only
           ? false : true
      });
    } else {
      this.setState({ 
        shared_data_period_selected: [],
        isConfirmAndUploadBtnDisable: true
      });
    }
  };

  scrollToTop = () => {
    if (this.state.selectDataPeriodRef.current) {
      this.state.selectDataPeriodRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  
  render() {
    const isCoach = this.props.currentRole === UserAssignmentRole.COACH;
    const isTeacher = this.props.currentRole ===  UserAssignmentRole.TEACHER;
    const isDistrictAdmin = this.props.currentRole ===  UserAssignmentRole.DISTRICT_OWNER;

    const { handleAcceptFile, uploadAssessmentData, assessments } = this.props;
    const { getEvidenceInDataPeriodLoading } = this.props.isLoading;
    const { 
      selectedAssessment, 
      grade, 
      timePeriod, 
      year, 
      isConfirmAndUploadBtnDisable,
      upload_in_existing_shared_dp,
      shared_data_period_selected,
      isContinueToUpload,
      isnewStudentsOnly,
      new_students_only
     } = this.state;

    const date = new Date();
    const currentYear = date.getFullYear();

    const possibleYears: ReactSelectOption<string>[] = [];
    for (let i = -5; i < 5; ++i) {
      possibleYears.push({
        value: (currentYear + i).toString(),
        label: (currentYear + i).toString(),
      });
    }

    return (
      <div style={{ width: "80%", margin: "0 10%" }}>
        <div className="font-weight-bold mb-1">
          This looks like an assessment we recognize. Confirm which one.
        </div>
        <div className="assessmentOptionsHeader">ASSESSMENT</div>
        {assessments!.map((assessment) => (
          <div
            key={assessment.id}
            className="assessmentOption"
            onClick={this.handleAssessmentSelect(assessment)}
          >
            <FontAwesomeIcon
              icon={
                selectedAssessment && selectedAssessment.id === assessment.id
                  ? faDotCircle
                  : faCircle
              }
              style={{ marginRight: "30px" }}
            />
            {assessment.name}
          </div>
        ))}

        {selectedAssessment && (selectedAssessment.source === "linkit" || selectedAssessment.source === "linkit_dibels8_new") && (
          <div style={{ marginTop: 12 }}>
            <h3>Looks like this was exported from LinkIt.</h3>
            <p>
              You'll need to let us know which Grade, Time Period, and Year the
              data represents
            </p>

            <Row>
              <Col md={4}>
                <h4 className="font-weight-bold m-0">Grade</h4>
                <Select
                  value={GradeLevels.find((gl) => gl.value === grade)}
                  menuPlacement="auto"
                  options={GradeLevels}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  styles={{
                    menuPortal: (styles) => ({ ...styles, zIndex: 100 }),
                  }}
                  openMenuOnFocus
                  onChange={(grade: any) =>
                    this.setState({ grade: grade.value as any })
                  }
                />
              </Col>

              <Col md={4}>
                <h4 className="font-weight-bold m-0">Time Period</h4>
                <Select
                  value={TimePeriodOptions.find((tp) => tp.value == timePeriod)}
                  menuPlacement="auto"
                  options={TimePeriodOptions}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  styles={{
                    menuPortal: (styles) => ({ ...styles, zIndex: 100 }),
                  }}
                  openMenuOnFocus
                  onChange={(timePeriod: any) =>
                    this.setState({ timePeriod: timePeriod.value as any })
                  }
                />
              </Col>

              <Col md={4}>
                <h4 className="font-weight-bold m-0">Year</h4>
                <Select
                  value={possibleYears.find((y) => y.value === year)}
                  menuPlacement="auto"
                  options={possibleYears}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  styles={{
                    menuPortal: (styles) => ({ ...styles, zIndex: 100 }),
                  }}
                  openMenuOnFocus
                  onChange={(year: any) => this.setState({ year: year.value })}
                />
              </Col>
            </Row>
          </div>
        )}

        {!isDistrictAdmin && <div style={{ borderBottom: "2px solid #DEDEDE", margin: "20px 0" }} />}
{/* Message 1 */}
        {!isDistrictAdmin ? 
        <div className="msgClass">
            <div className="d-flex">
              <p className="mr-2">
                <FontAwesomeIcon 
                  icon={faExclamationCircle}
                  style={{color: "#3b7ef1"}}
                /> 
              </p>
              <p>
                Do you want to upload data into an existing shared data period(s) or into a data period owned by you 
                (one owned by you will be created if it does not already exist)?
              </p>
            </div>
            <div className="form-group ml-4">
              <div className="form-check d-flex">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioExistOptions"
                  id="inlineRadio2"
                  onClick={() => this.setState({
                    upload_in_existing_shared_dp: true,
                    isContinueToUpload: true,
                    isConfirmAndUploadBtnDisable: 
                      this.state.upload_in_existing_shared_dp 
                      && this.state.isContinueToUpload
                      && this.state.new_students_only
                      ? false : true
                  })}
                  checked={upload_in_existing_shared_dp === true}
                  value={upload_in_existing_shared_dp as any}
                />
                <label className="form-check-label" htmlFor="inlineRadio2">Existing shared data period</label>
                {upload_in_existing_shared_dp &&
                  <div className="col-7" ref={this.state.selectDataPeriodRef}>
                     <Select
                        className="dataFiltersSelect"
                        isMulti={true}
                        value={this.props.dataPeriods.filter((x) => shared_data_period_selected?.includes(x.id))}
                        options={this.props.dataPeriods
                                  .filter((value) => (isCoach || isTeacher) 
                                                      && this.props.myId !== value.user_id)}
                        getOptionLabel={(value: DataPeriod) =>
                          `${value.name}${
                            (isCoach || isTeacher) && this.props.myId !== value.user_id ? ` (Shared by ${
                              value.user_name ? value.user_name : value.user_email
                            })` : ""
                          }`
                        }
                        isOptionDisabled={() => shared_data_period_selected != undefined ? shared_data_period_selected!.length >= 3 : false}
                        getOptionValue={(value: DataPeriod) => value.id.toString()}
                        onChange={this.handleSharedDataPeriodChange}
                        placeholder="Select Existing Data Period"
                      />
                 </div>
                }
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioExistOptions"
                  id="inlineRadio1"
                  onClick={() => this.setState({
                    upload_in_existing_shared_dp: false,
                    shared_data_period_selected: undefined,
                    isContinueToUpload: true,
                    isConfirmAndUploadBtnDisable: 
                      this.state.upload_in_existing_shared_dp 
                      && this.state.isContinueToUpload
                      && this.state.new_students_only
                      ? false : true
                  })}
                  checked={upload_in_existing_shared_dp === false}
                  value={upload_in_existing_shared_dp as any}
                />
                <label className="form-check-label" htmlFor="inlineRadio1">Data period owned by me</label>
              </div>
            </div>
        </div>
        :
        ''}
        {isDistrictAdmin || upload_in_existing_shared_dp != undefined 
        ? 
        <>
          {/* Message 1 End */}
          <div style={{ borderBottom: "2px solid #DEDEDE", margin: "20px 0" }} />
            {isDistrictAdmin || isContinueToUpload != undefined ?
              <>
          {/* Message 2 start */}
              <div className="msgClass">
                <div className="d-flex">
                  <p className="mr-2">
                    <FontAwesomeIcon 
                      icon={faExclamationCircle}
                      style={{color: "#3b7ef1"}}
                    /> 
                  </p>
                  <p> This data period may be shared with other users who have already made decisions using the data. 
                    All users with whom the data period may be shared will have access to this uploaded data based on 
                    their data permissions. Are you sure you want to continue with the upload?</p>
                </div>
                <div className="d-flex align-items-between ml-4">
                  <button
                    className="blueBtnSm" 
                    onClick={() => this.setState({isnewStudentsOnly : true})}
                  >
                    Yes
                  </button>
                  <button 
                    className="whiteBtnSm ml-2"
                    onClick={this.handleContinueToUploadReset}>
                    No
                  </button>
                </div>
              </div>
              <div style={{ borderBottom: "2px solid #DEDEDE", margin: "20px 0" }} />
          {/* Message 2 End */}
              {isnewStudentsOnly != undefined
              ?
              <>
        {/* Message 3 start */}
              <div className="msgClass">
                <div className="d-flex">
                  <p className="mr-2">
                    <FontAwesomeIcon 
                      icon={faExclamationCircle}
                      style={{color: "#3b7ef1"}}
                    /> 
                  </p>
                  <p>
                  To upload data, choose from one of the following options (click an option to view the description):
                  {/* The existing data period may already contain <strong>Assessment</strong> data for the same students in the uploaded file. 
                  In this case, do you want to: */}
                  </p>
                </div>
                {/* <ul>
                  <li>replace the existing assessment data</li>
                  <li>append the existing assessment data</li>
                  <li>upload data for new students only who are not in the existing data period</li>
                </ul>

                <p className="ml-4">
                    If you replace or append assessment data, this may result in an update to the {" "} 
                     <strong>Content Area of Need</strong> information and a need to adjust {" "}
                    <strong>Diagnostic Info</strong>.
                </p> */}
                <div className="row">
                  <div className={new_students_only ? "col-6" : "col-12"}>
                    <div className="form-group ml-4">
                      <div className="d-flex justify-content-between">
                        <div className="form-check mb-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioNewStudentOptions"
                            id="inlineRadio6"
                            onClick={() => {
                              this.setState({
                                new_students_only: ReplaceAppendNewOption.CREATE,
                                isConfirmAndUploadBtnDisable: upload_in_existing_shared_dp && !shared_data_period_selected?.length,
                                videoVisible: false
                              }, () => setTimeout(() => {
                                this.setState({ videoVisible: true})
                              }, 500))
                              if(upload_in_existing_shared_dp && !shared_data_period_selected?.length) {
                                this.scrollToTop();
                              }
                            }
                          }
                            value={new_students_only as any}
                          />
                          <label className="form-check-label font-label-strong" htmlFor="inlineRadio6"><strong>Create a new data period</strong></label>

                        </div>
                          {new_students_only == ReplaceAppendNewOption.CREATE
                            ?
                              <FontAwesomeIcon 
                                icon={faArrowRight}
                                style={{color: "##2547a5"}}
                              /> 
                              : ''}
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="form-check mb-2 d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioNewStudentOptions"
                            id="inlineRadio3"
                            onClick={() => {
                              this.setState({
                                new_students_only: ReplaceAppendNewOption.REPLACE,
                                isConfirmAndUploadBtnDisable: upload_in_existing_shared_dp && !shared_data_period_selected?.length,
                                videoVisible: false
                              }, () => setTimeout(() => {
                                this.setState({ videoVisible: true})
                              }, 500))
                              if(upload_in_existing_shared_dp && !shared_data_period_selected?.length) {
                                this.scrollToTop();
                              }
                            }
                          }
                            value={new_students_only as any}
                          />
                          <label className="form-check-label font-label-strong" htmlFor="inlineRadio3">
                            <strong>Replace data for existing students AND/OR add new student data</strong>
                          </label>
                        </div>
                          {new_students_only == ReplaceAppendNewOption.REPLACE 
                          ?
                            <FontAwesomeIcon 
                              icon={faArrowRight}
                              style={{color: "##2547a5"}}
                            /> 
                            : ''}
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="form-check mb-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioNewStudentOptions"
                            id="inlineRadio4"
                            onClick={() => {
                                this.setState({
                                  new_students_only: ReplaceAppendNewOption.APPEND,
                                  isConfirmAndUploadBtnDisable: upload_in_existing_shared_dp && !shared_data_period_selected?.length,
                                  videoVisible: false
                                }, () => setTimeout(() => {
                                  this.setState({ videoVisible: true})
                                }, 500))
                                if(upload_in_existing_shared_dp && !shared_data_period_selected?.length) {
                                  this.scrollToTop();
                                }
                              }
                            }
                            value={new_students_only as any}
                          />
                          <label className="form-check-label font-label-strong" htmlFor="inlineRadio4"><strong>Add data for existing students</strong></label>
                        </div>
                        {new_students_only == ReplaceAppendNewOption.APPEND
                        ? 
                        <FontAwesomeIcon 
                          icon={faArrowRight}
                          style={{color: "##2547a5"}}
                        /> 
                        : '' }
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="form-check mb-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioNewStudentOptions"
                            id="inlineRadio5"
                            onClick={() => {
                                this.setState({
                                new_students_only: ReplaceAppendNewOption.NEW,
                                isConfirmAndUploadBtnDisable: upload_in_existing_shared_dp && !shared_data_period_selected?.length,
                                videoVisible: false
                              }, () => setTimeout(() => {
                                this.setState({ videoVisible: true})
                              }, 500))
                              if(upload_in_existing_shared_dp && !shared_data_period_selected?.length) {
                                this.scrollToTop();
                              }
                            }
                          }
                            value={new_students_only as any}
                          />
                          <label className="form-check-label font-label-strong" htmlFor="inlineRadio5"><strong>Add data for existing students AND/OR add new student data</strong></label>
                        </div>
                        {new_students_only == ReplaceAppendNewOption.NEW
                        ? 
                        <FontAwesomeIcon 
                          icon={faArrowRight}
                          style={{color: "##2547a5"}}
                        /> 
                        : '' }
                      </div>
                    </div>
                  </div>
                  {new_students_only == ReplaceAppendNewOption.CREATE
                  ?
                  <>
                    <div className={`col-6 initialFade ${this.state.videoVisible ?'in' : 'out'}`}>
                        <p>Choose this option to upload a file(s) to create a new data period that does not currently exist. </p>
                        <video width="340" height="170" controls>
                          <source src="https://readycoach-bucket.s3.us-east-2.amazonaws.com/readycoach-bucket/system/tutorial_videos/Video-Create-new-data-period.webm" type="video/webm"/>
                          Your browser does not support HTML video.
                        </video>
                      </div>
                  </> 
                  : ''}
                  
                  {new_students_only == ReplaceAppendNewOption.REPLACE
                  ? 
                  <>
                    <div className={`col-6 initialFade ${this.state.videoVisible ?'in' : 'out'}`}>
                      <p>Choose this option when uploading a file(s) that contains a change in student information (e.g., demographics, indicators) 
                        in an existing data period and/or contains information (e.g., demographics, indicators) for new students. 
                        This may result in an update to the Content Area of Need information and a need to adjust Diagnostic Info.​</p>
                      <video width="340" height="170" controls>
                        <source src="https://readycoach-bucket.s3.us-east-2.amazonaws.com/readycoach-bucket/system/tutorial_videos/Video-Replace-data-for-existing-students-ANDOR-add-new-student-data.webm" type="video/webm"/>
                        Your browser does not support HTML video.
                      </video>
                    </div>
                  </>
                  : ''}

                  {new_students_only == ReplaceAppendNewOption.APPEND
                  ? <>
                    <div className={`col-6 initialFade ${this.state.videoVisible ?'in' : 'out'}`}>
                      <p>Choose this option to upload a file(s) that contains new information (e.g., demographics, indicators) for 
                        existing students in a data period. This may result in an update to the Content Area of Need information 
                        and a need to adjust Diagnostic Info.​ </p>
                      <video width="340" height="170" controls>
                        <source src="https://readycoach-bucket.s3.us-east-2.amazonaws.com/readycoach-bucket/system/tutorial_videos/Video-Add-data-for-existing-students.webm" type="video/webm"/>
                        Your browser does not support HTML video.
                      </video>
                    </div>
                    </>
                  : ''}

                  {new_students_only == ReplaceAppendNewOption.NEW
                  ? <>
                    <div className={`col-6 initialFade ${this.state.videoVisible ?'in' : 'out'}`}>
                      <p>Choose this option to upload a file(s) that contains new information (e.g., demographics, indicators) for 
                        existing students in a data period, and/or contains new students, and/or contains new information (e.g., demographics, indicators) 
                        for both new and existing students. This may result in an update to the Content Area of Need information 
                        and a need to adjust Diagnostic Info.​ </p>
                      <video width="340" height="170" controls>
                        <source src="https://readycoach-bucket.s3.us-east-2.amazonaws.com/readycoach-bucket/system/tutorial_videos/Video-Add-data-for-existing-ANDOR-new-students.webm" type="video/webm"/>
                        Your browser does not support HTML video.
                      </video>
                    </div>
                    </>
                  : ''}

                </div>
              </div>
              <div style={{ borderBottom: "2px solid #DEDEDE", margin: "20px 0" }} />
        {/* Message 3 End */}
        {new_students_only != undefined
          ?
          <div className="alert alert-danger mt-2"> 
            <FontAwesomeIcon 
              icon={faExclamationTriangle}
              style={{color: "#721c24"}}
            /> {" "}
            Once data is uploaded, this action cannot be undone. 
            Are you sure you want to continue?
          </div>
          : ''
        }
        </>
        :
        ''
          
        }
              </>
            :
            ''}
        </>
        :
        <div style={{ borderBottom: "2px solid #DEDEDE", margin: "20px 0" }} />
        
        }
        <div className={`d-flex justify-content-between ${this.props.currentRole == 'district_owner' ? 'float-right': ''}`}>
          {this.props.currentRole != 'district_owner' ? 
            <div>
              <button className="blueBtnMd" onClick={handleAcceptFile}>
                None of them
              </button>
            </div>
          : ''}
          <div>
            <button
              data-cy="select-assessment-btn"
              className="blueBtnMd"
              onClick={this.handleSelectAssessmentConfirm}
              disabled={isConfirmAndUploadBtnDisable ? true : ((uploadAssessmentData || getEvidenceInDataPeriodLoading)
              || (selectedAssessment && ["linkit","linkit_dibels8_new"].includes(selectedAssessment.source!) 
                  && (grade == undefined || timePeriod == undefined || year == undefined))
              )}
            >
              Confirm and Upload{" "}
              {(uploadAssessmentData || getEvidenceInDataPeriodLoading) && (
                <Spinner animation="border" size="sm" />
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ onboarding, auth }: ApplicationState): PropsFromState => {
  return {
    evidencePeriodData: onboarding.evidencePeriodData,
    currentRole: auth.userInfo?.profile.current_role,
    myId: auth.userInfo?.id,
    isLoading: {
      getEvidenceInDataPeriodLoading:
        onboarding.isLoading.getEvidenceInDatePeriod,
    },
    dataPeriods: onboarding.dataPeriods,
  };
};

export default connect(mapStateToProps)(FileRecognizedContent);
